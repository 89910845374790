<template>
  <div id="app">
    <router-view />
    <audio id="ring" src="@/assets/ts.wav" controls hidden></audio>
  </div>
</template>

<script>
import ring from '@/assets/ts.wav'
export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },
  watch: {},
  mounted(){
    this.$ImSdk.initIm(this)
  },
  methods: {}
}
</script>
<style>
/* 引入字体文件 */
	
@font-face {
  font-family: "PingFangSC-Regular";
	src:url('https://cdn.binliyoujia.com/font/PingFang Medium_0.ttf'); /* 字体文件的URL */
}
@font-face {
	font-family: 'Montserrat'; /* 自定义字体名称 */
	src:url('https://cdn.binliyoujia.com/font/Montserrat-Bold.otf'); /* 字体文件的URL */
}
@font-face {
	font-family: 'PingFangSC-Regular'; /* 自定义字体名称 */
	src:url('https://cdn.binliyoujia.com/font/PingFang Regular_0.ttf'); /* 字体文件的URL */
}
#app {
  font-family: PingFang SC, Montserrat, PingFangSC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  border-radius: 10px;
  user-select: none;
}
body{
  background: transparent;
  overflow: hidden;
}

</style>
