/**
 * 基础路由
 * @type { *[] }
 */

export const constantRouterMap = [
  {
    path: '/',
    component:() => import('@/views/main/Index'),
    name: 'Main', 
    meta: {
      title: 'Im',
      rand: Math.random()
    }
  },
  {
    path: '/login',
    component: () => import('@/views/login/Index'),
    name: 'Login', 
    meta: {
      title: '登陆',
      rand: Math.random()
    },
  },
  { 
    path: '/userxy',
    component: () => import('@/views/userxy/Index'),
    name: 'UserXy',
    meta: {
      title: '用户协议',
      rand: Math.random()
    },
  },
  { 
    path: '/yszc',
    component: () => import('@/views/yszc/Index'),
    name: 'YsZc',
    meta: {
      title: '隐私政策',
      rand: Math.random()
    },
  },
  { 
    path: '/preimg',
    component: () => import('@/views/preimg/Index'),
    name: 'PreImg',
    meta: {
      title: '图片预览',
    },
  },
  {
    path: '/findpwd',
    component: () => import('@/views/findpwd/Index'),
    name: 'FindPwd',
    meta: { 
      title: '找回密码'
    },
  }
]